<template>
    <v-dialog v-model="show" max-width="700px">
        <v-card>
            <v-card-title>
                <span class="headline" v-if="isEdited">{{
                    $t("trainings.edit_training")
                }}</span>
                <span class="headline" v-else>{{
                    $t("trainings.add_training")
                }}</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="training.oculus_id"
                                    :label="$t('trainings.oculus')"
                                    :items="oculusDevices"
                                    item-text="name"
                                    item-value="oculus_id"
                                    :rules="rules"
                                    dense
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="training.client_id"
                                    :label="$t('trainings.client')"
                                    :items="clients"
                                    item-text="name"
                                    item-value="id"
                                    :rules="rules"
                                    dense
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="training.modul_id"
                                    :label="$t('trainings.modul')"
                                    :items="moduls"
                                    @change="filterScenarios()"
                                    item-text="name"
                                    item-value="id"
                                    :rules="rules"
                                    dense
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="training.scenario_id"
                                    :label="$t('trainings.scenario')"
                                    :items="copiedScenarios"
                                    :disabled="!training.modul_id"
                                    item-text="name"
                                    item-value="id"
                                    :rules="rules"
                                    dense
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark @click="submit">{{
                    $t("trainings.start")
                }}</v-btn>
                <v-btn @click.stop="show = false">{{
                    $t("general.cancel")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "TrainingForm",
    props: {
        form: {
            type: Object,
        },
        training: Object,
        visible: Boolean,
        isEdited: Boolean,
        oculusDevices: Array,
        clients: Array,
        moduls: Array,
        scenarios: Array,
    },

    data() {
        return {
            checkedDefault: true,
            valid: true,
            showEye: false,
            rules: [(value) => !!value || this.$t("general.required")],
            copiedScenarios: []
        };
    },

    created()  {
        this.copiedScenarios = [...this.scenarios]
    },

    watch: {
        show(val) {
            !val && this.$refs.form.reset();
        },
    },

    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) {
                    this.$emit("close");
                }
            },
        },
    },

    methods: {
        filterScenarios() {
            this.copiedScenarios = this.scenarios.filter(value => value.modul_id == this.training.modul_id)
        },

        async submit() {
            if (this.$refs.form.validate()) {
                if (this.isEdited) {
                    await axios
                        .put(
                            `/api/updateTraining/${this.training.id}`,
                            this.training,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " +
                                        this.$store.state.tokenData.user
                                            .access_token,
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.isError) {
                                this.$emit(
                                    "snackMessage",
                                    response.data.message,
                                    "warning"
                                );
                                this.$refs.email.focus();
                            } else {
                                // response.data.roleName = response.data.role == "admin" ? "Admin": "Uživatel";
                                this.$emit("updateTraining", response.data);
                                this.$emit(
                                    "snackMessage",
                                    "trainings.training_saved",
                                    "success"
                                );
                                this.$emit("close");
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                } else {
                    await axios
                        .post(`/api/storeTraining`, this.training, {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    this.$store.state.tokenData.user
                                        .access_token,
                            },
                        })
                        .then((response) => {
                            if (response.data.isError) {
                                this.$refs.email.focus();
                                this.$emit(
                                    "snackMessage",
                                    response.data.message,
                                    "warning"
                                );
                            } else {
                                this.$emit("addTraining", response.data);
                                this.$emit(
                                    "snackMessage",
                                    "trainings.training_added",
                                    "success"
                                );
                                this.$emit("close");
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                }
            }
        },
    }
};
</script>
