<template>
    <div>
        <header>
            <v-container>
                <v-row no-gutters>
                    <h1 class="font-weight-light headline">
                        {{ $t("title.results") }}
                    </h1>
                    <v-spacer></v-spacer>
                    <a style="text-decoration:none" href="/export"><v-btn
                        color="blue-grey"
                        dark
                        small
                        >{{ $t("results.export") }}</v-btn
                    ></a>
                </v-row>
            </v-container>
        </header>
        <section class="py-7">
            <v-container>
                <v-row v-if="isResultsLoaded && !results.length">
                    <v-col cols="12" md="4" class="offset-md-4">
                        <v-alert
                            icon="mdi-information-outline"
                            type="info"
                            text
                            border="top"
                        >
                            {{ $t("general.we_dont_have_data_yet") }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-sheet class="px-3 pt-3 pb-3" v-if="!isResultsLoaded">
                    <v-skeleton-loader
                        class="mx-auto"
                        type="table"
                    ></v-skeleton-loader>
                </v-sheet>
                <v-card v-else>
                    <v-row style="margin-left:7px;" v-if="results.length">
                        <v-col cols="12" sm="2">
                            
                        </v-col>
                        <v-col cols="12" sm="2">
                            
                        </v-col>
                    </v-row>
                    <v-data-table
                        v-if="results.length"
                        :hide-default-footer="true"
                        :disable-pagination="true"
                        :items="results"
                        :headers="headers"
                        dense
                    >
                        <template v-slot:body.prepend="{ headers, item }">
                            <tr>
                                <td
                                    v-for="header in headers"
                                    :key="header.value"
                                    class="table-filter"
                                >
                                    <v-select
                                        v-model="modulFilterValue"
                                        v-if="header.value === 'module_name'"
                                        :items="moduls"
                                        item-text="name"
                                        item-value="name"
                                        dense
                                        :placeholder="$t('trainings.modul')"
                                    >
                                    </v-select>
                                    <v-select
                                        v-if="header.value === 'scenario_name'"
                                        v-model="scenarioFilterValue"
                                        :items="scenarios"
                                        item-text="name"
                                        item-value="name"
                                        dense
                                        :placeholder="$t('trainings.scenario')"
                                    >    
                                    </v-select>
                                    <v-select
                                        v-if="header.value === 'station_name'"
                                        v-model="stationFilterValue"
                                        :items="stations"
                                        item-text="name"
                                        item-value="name"
                                        dense
                                        :placeholder="$t('trainings.station')"
                                    >    
                                    </v-select>
                                    <v-autocomplete
                                        v-if="header.value === 'title'"
                                        v-model="actionFilterValue"
                                        :items="actions"
                                        item-text="title"
                                        item-value="name"
                                        dense
                                        :placeholder="$t('results.error_name')"
                                    >    
                                    </v-autocomplete>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-container>
        </section>
    </div>
</template>

<script>

export default {
    data() {
        return {
            modulFilterValue: 0,
            scenarioFilterValue: 0,
            actionFilterValue: 0,
            stationFilterValue: 0,
            moduls:[],
            actions:[],
            stations:[],
            scenarios:[],
            results: [],
            isResultsLoaded: false
        };
    },

    components: {
        
    },

    computed: {
       headers() {
            return [
                {
                    text: this.$t("trainings.modul"),
                    align: "left",
                    sortable: false,
                    value: "module_name",
                    filter: this.modulFilter
                },
                {
                    text: this.$t("trainings.scenario"),
                    align: "left",
                    sortable: false,
                    value: "scenario_name",
                    filter: this.scenarioFilter
                },
                {
                    text: this.$t("results.station"),
                    align: "left",
                    sortable: false,
                    value: "station_name",
                    filter: this.stationFilter
                },
                {
                    text: this.$t("results.error_name"),
                    align: "left",
                    sortable: false,
                    value: "title",
                    filter: this.actionFilter
                },
                {
                    text: this.$t("trainings.number"),
                    align: "left",
                    sortable: true,
                    value: "count",
                }
            ];
        },
    },

    created() {
       this.getResults()
       this.getScenarios()
       this.getModuls()
       this.getActions()
       this.getStations()
    },

    methods: {
        async getResults() {
            await axios
                .get("/api/getResults", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.results = response.data;
                    this.results.sort(function (a, b) {
                        if (a.station_id > b.station_id) {
                            return 1;
                        }
                        if (a.station_id < b.station_id) {
                            return -1;
                        }
                        return 0;
                    });
                    this.isResultsLoaded = true;
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        async getScenarios() {
            await axios
                .get("/api/scenarioList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    let allScenarios = {
                        id: 0,
                        name: this.$t("general.all"),
                    };
                    this.scenarios.push(allScenarios);
                    this.scenarios = this.scenarios.concat(response.data)
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        async getModuls() {
            await axios
                .get("/api/modulList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    let allModuls = {
                        id: 0,
                        name: this.$t("general.all"),
                    };
                    this.moduls.push(allModuls);
                    this.moduls = this.moduls.concat(response.data)
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        async getActions() {
            await axios
                .get("/api/actionList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    let allActions = {
                        id: 0,
                        title: this.$t("general.all"),
                    };
                    this.actions.push(allActions);
                    this.actions = this.actions.concat(response.data)
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        async getStations() {
            await axios
                .get("/api/getStations", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    let allStations = {
                        id: 0,
                        name: this.$t("general.all"),
                    };
                    this.stations.push(allStations);
                    this.stations = this.stations.concat(response.data)
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        modulFilter(value) {
            if (!this.modulFilterValue || this.modulFilterValue == this.$t("general.all")) {
                return true;
            }
            return value != null && value == this.modulFilterValue;
        },
        scenarioFilter(value) {
            if (!this.scenarioFilterValue || this.scenarioFilterValue == this.$t("general.all")) {
                return true;
            }
            return value != null && value == this.scenarioFilterValue;
        },
        stationFilter(value) {
            if (!this.stationFilterValue || this.stationFilterValue == this.$t("general.all")) {
                return true;
            }
            return value != null && value == this.stationFilterValue;
        },
        actionFilter(value) {
            if (!this.actionFilterValue || this.actionFilterValue == this.$t("general.all")) {
                return true;
            }
            return value != null && value == this.actionFilterValue;
        },
        
    }    
};
</script>
