<template>
    <v-app id="app-content">
        <v-navigation-drawer :mini-variant="primaryDrawer.mini" app clipped>
            <v-list>
                <router-link
                    class="d-block text-decoration-none"
                    :to="{ name: 'trainings' }"
                    v-if="$store.state.profile.role === 'admin'"
                >
                    <v-tooltip right v-if="primaryDrawer.mini">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item link v-bind="attrs" v-on="on">
                                <div class="ap-list-button">
                                    <v-icon>mdi-school</v-icon>
                                </div>
                            </v-list-item>
                        </template>
                        <span>{{ $t("title.trainings") }}</span>
                    </v-tooltip>
                    <v-list-item link v-else>
                        <v-list-item-action>
                            <v-icon>mdi-school</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("title.trainings")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                <!-- <label
                    class="py-2 pl-4 d-block itb-drawer-label mt-3"
                    v-if="$store.state.profile.role === 'admin'"
                >
                    <v-icon v-if="primaryDrawer.mini">mdi-poll</v-icon>
                    <span v-else>{{ $t("title.analytics") }}</span>
                </label>

                <router-link
                    class="d-block text-decoration-none"
                    :to="{ name: 'results' }"
                    v-if="$store.state.profile.role === 'admin'"
                >
                    <v-tooltip right v-if="primaryDrawer.mini">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item link v-bind="attrs" v-on="on">
                                <div class="ap-list-button">
                                    <v-icon>mdi-list-status</v-icon>
                                </div>
                            </v-list-item>
                        </template>
                        <span>{{ $t("title.results") }}</span>
                    </v-tooltip>
                    <v-list-item link v-else>
                        <v-list-item-action>
                            <v-icon>mdi-list-status</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("title.results")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link> -->

                <label
                    class="py-2 pl-4 d-block itb-drawer-label mt-3"
                    v-if="$store.state.profile.role === 'admin'"
                >
                    <v-icon v-if="primaryDrawer.mini">mdi-cog-outline</v-icon>
                    <span v-else>{{ $t("title.settings") }}</span>
                </label>

                <router-link
                    class="d-block text-decoration-none"
                    :to="{ name: 'oculus-devices' }"
                    v-if="$store.state.profile.role === 'admin'"
                >
                    <v-tooltip right v-if="primaryDrawer.mini">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item link v-bind="attrs" v-on="on">
                                <div class="ap-list-button">
                                    <v-icon>mdi-safety-goggles</v-icon>
                                </div>
                            </v-list-item>
                        </template>
                        <span>{{ $t("title.oculus_devices") }}</span>
                    </v-tooltip>
                    <v-list-item link v-else>
                        <v-list-item-action>
                            <v-icon>mdi-safety-goggles</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("title.oculus_devices")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                <router-link
                    class="d-block text-decoration-none"
                    :to="{ name: 'clients' }"
                    v-if="$store.state.profile.role === 'admin'"
                >
                    <v-tooltip right v-if="primaryDrawer.mini">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item link v-bind="attrs" v-on="on">
                                <div class="ap-list-button">
                                    <v-icon>mdi-account-hard-hat</v-icon>
                                </div>
                            </v-list-item>
                        </template>
                        <span>{{ $t("title.clients") }}</span>
                    </v-tooltip>
                    <v-list-item link v-else>
                        <v-list-item-action>
                            <v-icon>mdi-account-hard-hat</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("title.clients")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                <router-link
                    class="d-block text-decoration-none"
                    :to="{ name: 'users' }"
                    v-if="$store.state.profile.role === 'admin'"
                >
                    <v-tooltip right v-if="primaryDrawer.mini">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item link v-bind="attrs" v-on="on">
                                <div class="ap-list-button">
                                    <v-icon>mdi-account-multiple</v-icon>
                                </div>
                            </v-list-item>
                        </template>
                        <span>{{ $t("title.users") }}</span>
                    </v-tooltip>
                    <v-list-item link v-else>
                        <v-list-item-action>
                            <v-icon>mdi-account-multiple</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("title.users")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                <router-link
                    class="d-block text-decoration-none"
                    :to="{ name: 'companies' }"
                    v-if="$store.state.profile.role === 'admin'"
                >
                    <v-tooltip right v-if="primaryDrawer.mini">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item link v-bind="attrs" v-on="on">
                                <div class="ap-list-button">
                                    <v-icon>mdi-factory</v-icon>
                                </div>
                            </v-list-item>
                        </template>
                        <span>{{ $t("title.companies") }}</span>
                    </v-tooltip>
                    <v-list-item link v-else>
                        <v-list-item-action>
                            <v-icon>mdi-factory</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("title.companies")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                <router-link
                    class="d-block text-decoration-none"
                    :to="{ name: 'divisions' }"
                    v-if="$store.state.profile.role === 'admin'"
                >
                    <v-tooltip right v-if="primaryDrawer.mini">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item link v-bind="attrs" v-on="on">
                                <div class="ap-list-button">
                                    <v-icon>mdi-file-tree</v-icon>
                                </div>
                            </v-list-item>
                        </template>
                        <span>{{ $t("title.divisions") }}</span>
                    </v-tooltip>
                    <v-list-item link v-else>
                        <v-list-item-action>
                            <v-icon>mdi-file-tree</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("title.divisions")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                <router-link
                    class="d-block text-decoration-none"
                    :to="{ name: 'sections' }"
                    v-if="$store.state.profile.role === 'admin'"
                >
                    <v-tooltip right v-if="primaryDrawer.mini">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item link v-bind="attrs" v-on="on">
                                <div class="ap-list-button">
                                    <v-icon
                                        >mdi-badge-account-horizontal-outline</v-icon
                                    >
                                </div>
                            </v-list-item>
                        </template>
                        <span>{{ $t("title.sections") }}</span>
                    </v-tooltip>
                    <v-list-item link v-else>
                        <v-list-item-action>
                            <v-icon
                                >mdi-badge-account-horizontal-outline</v-icon
                            >
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("title.sections")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app clipped-left dark color="primary">
            <v-app-bar-nav-icon
                @click.stop="primaryDrawer.mini = !primaryDrawer.mini"
            />
            <v-toolbar-title>
                <img
                    v-if="domain == 'vr.recutech.cz'"
                    align="middle"
                    src="/images/logo-admin.png"
                    height="30"
                />
                <h3 v-else class="mt-2">recutech test</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="$store.state.profile.role === 'admin'">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="yellow"
                        class="mr-2 itb-icon-tooltip"
                        >mdi-crown-outline</v-icon
                    >
                </template>
                <span>{{ $t("general.wau_you_are_admin") }}</span>
            </v-tooltip>
            {{ $store.state.profile.email }}
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="logout"
                        color="deep-orange accent-3"
                    >
                        <v-icon>mdi-logout-variant</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t("general.sign_out") }}</span>
            </v-tooltip>
        </v-app-bar>

        <v-main>
            <router-view></router-view>
        </v-main>

        <v-footer app color="white">
            <span>
                &copy; 2022
                <a href="https://vrband.eu" target="_blank">vr_BAND</a>
            </span>
        </v-footer>
    </v-app>
</template>
<script>
export default {
    data: () => ({
        primaryDrawer: {
            mini: false,
        },
        domain: "",
    }),

    created() {
        let url = window.location.href;
        if (url.includes("vr.recutech.cz")) {
            this.domain = "vr.recutech.cz";
        }
    },

    methods: {
        logout() {
            axios
                .get("/api/auth/logout", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then(() => {
                    localStorage.removeItem("itb-spa-token");
                    this.$store.dispatch("resetState");
                    this.$router.push("/login");
                });
        },
    },
};
</script>
