<template>
    <div>
        <header>
            <v-container>
                <v-row no-gutters>
                    <h1 class="font-weight-light headline">
                        {{ $t("title.trainings") }}
                    </h1>
                    <v-spacer></v-spacer>
                    <v-lazy transition="fade-transition">
                        <v-btn
                            color="success"
                            class="mr-2"
                            dark
                            small
                            @click.stop="
                                openForm()
                            "
                            >{{ $t("general.add") }}</v-btn
                        >
                    </v-lazy>
                    <v-btn
                        color="blue-grey"
                        dark
                        @click="
                            exportDialog = true;
                            exportSelection = 0;
                        "
                        small
                    >
                        {{ $t("results.export") }}
                    </v-btn>
                </v-row>
            </v-container>
        </header>
        <section class="py-7">
            <v-container>
                <v-row v-if="isTrainingsLoaded && !trainings.length">
                    <v-col cols="12" md="4" class="offset-md-4">
                        <v-alert
                            icon="mdi-information-outline"
                            type="info"
                            text
                            border="top"
                        >
                            {{ $t("general.we_dont_have_data_yet") }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-sheet class="px-3 pt-3 pb-3" v-if="!isTrainingsLoaded">
                    <v-skeleton-loader
                        class="mx-auto"
                        type="table"
                    ></v-skeleton-loader>
                </v-sheet>
                <v-card v-else>
                    <v-data-table
                        v-if="trainings.length"
                        :headers="headers"
                        :items="trainings"
                    >
                        <template v-slot:item.action="{ item }">
                            <div style="display: flex">
                                <!-- <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-tr-class-on-hover="'blue'"
                                            size="20px"
                                            color="blue"
                                            class="mr-2"
                                            @click="openResults(item)"
                                            v-on="on"
                                            >icono
                                            mdi-clipboard-list-outline</v-icon
                                        >
                                    </template>
                                    <span>{{
                                        $t("trainings.see_result")
                                    }}</span>
                                </v-tooltip> -->
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-if="item.state == 1"
                                            size="22px"
                                            color="red"
                                            v-tr-class-on-hover="'red'"
                                            class="mr-2"
                                            @click="confirmStop(item)"
                                            v-on="on"
                                            >mdi-stop-circle-outline</v-icon
                                        >
                                        <v-icon
                                            v-else
                                            size="22px"
                                            color="grey"
                                            class="mr-2"
                                            v-on="on"
                                            >mdi-stop-circle-outline</v-icon
                                        >
                                    </template>
                                    <span v-if="item.state > 0">{{
                                        $t("trainings.stop_training")
                                    }}</span>
                                    <span v-else>{{
                                        $t("trainings.training_stoped")
                                    }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-if="item.state != 1"
                                            size="22px"
                                            color="red"
                                            v-tr-class-on-hover="'red'"
                                            class="mr-2"
                                            @click="confirmDelete(item)"
                                            v-on="on"
                                            >mdi-delete-outline</v-icon
                                        >
                                        <v-icon
                                            v-else
                                            size="22px"
                                            color="grey"
                                            class="mr-2"
                                            v-on="on"
                                            >mdi-delete-outline</v-icon
                                        >
                                    </template>
                                    <span v-if="item.state != 1">{{ $t("general.delete") }}</span>
                                    <span v-else>{{ $t("trainings.cant_delete") }}</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-slot:body.prepend="{ headers, item }">
                            <tr>
                                <td
                                    v-for="header in headers"
                                    :key="header.value"
                                    class="table-filter"
                                >
                                    <v-select
                                        v-if="header.value === 'modul'"
                                        v-model="filtersHeader.moduleValueFilter"
                                        :items="modulesToFilter"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                    >
                                    </v-select>
                                    <v-select
                                        v-if="header.value === 'scenario'"
                                        v-model="filtersHeader.scenarioValueFilter"
                                        :items="scenariosToFilter"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                    >
                                    </v-select>
                                    <v-autocomplete
                                        v-if="header.value === 'client'"
                                        v-model="filtersHeader.clientValueFilter"
                                        :items="clientsToExport"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                    >
                                    </v-autocomplete>
                                    <v-autocomplete
                                        v-if="header.value === 'client.company'"
                                        v-model="filtersHeader.companyValueFilter"
                                        :items="companiesToFilter"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                    >
                                    </v-autocomplete>
                                    <v-select
                                        v-if="header.value === 'state'"
                                        v-model="filtersHeader.stateValueFilter"
                                        :items="statesToFilter"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                    >
                                        <template slot="item" slot-scope="data">
                                            <span :class="data.item.id == 1 ? 'red--text': data.item.id == 2 ? 'green--text': data.item.id == 3 ? 'blue--text' : data.item.id == 4 ? 'orange--text' : ''">{{data.item.name}}</span>
                                        </template>
                                    </v-select>
                                    <v-menu
                                        v-if="header.value === 'created_at'"
                                        v-model="menuFilter"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="formattedFilterDate"
                                                v-on="on"
                                                clearable
                                            >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="filtersHeader.createdValueFilter"
                                            @input="menuFilter = false"
                                            :locale="$i18n.locale"
                                        ></v-date-picker>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:item.client="{ item }">
                            <span v-if="item.client != null">{{
                                item.client.name
                            }}</span>
                            <span v-else>-</span>
                        </template>
                        <template v-slot:item.finished_at="{ item }">
                            <span v-if="item.finished_at == null">-</span>
                            <span v-else>{{ item.finished_at }}</span>
                        </template>
                        <template v-slot:item.state="{ item }">
                            <span v-if="item.state == 0" class="red--text">{{
                                $t("trainings.interrupted")
                            }}</span>
                            <span
                                v-else-if="item.state == 1"
                                class="green--text"
                                >{{ $t("trainings.active") }}</span
                            >
                            <span
                                v-else-if="item.state == 2"
                                class="blue--text"
                                >{{ $t("trainings.done") }}</span
                            >
                             <span
                                v-else-if="item.state == 3"
                                class="orange--text"
                                >{{ $t("trainings.expired") }}</span
                            >
                        </template>
                        <template v-slot:item.number="{ item }">
                            {{ item.number }}
                        </template>
                        <template v-slot:item.scenario="{ item }">
                            <span v-if="item.scenario != null">{{
                                item.scenario.name
                            }}</span>
                            <span v-else>-</span>
                        </template>
                         <template v-slot:item.modul="{ item }">
                            <span v-if="item.modul != null">{{
                                item.modul.name
                            }}</span>
                            <span v-else>-</span>
                        </template>
                        <template v-slot:item.client.company="{ item }">
                            <span v-if="item.client && item.client.company">{{
                                item.client.company.name
                            }}</span>
                            <span v-else>-</span>
                        </template>
                    </v-data-table>
                </v-card>
                <TrainingForm
                    v-if="dialog.trainingForm"
                    :training="selectedTraining"
                    :visible="dialog.trainingForm"
                    :isEdited="isTrainingEdited"
                    :oculusDevices="oculusDevices"
                    :clients="clients"
                    :scenarios="scenarios"
                    :moduls="moduls"
                    @addTraining="addTraining"
                    @updateTraining="updateTraining"
                    @close="dialog.trainingForm = false"
                    @snackMessage="snackMessage"
                />

                <ConfirmationDialog
                    :title="$t('trainings.stop_confirmation_title')"
                    :name="selectedTraining.name"
                    :text="'trainings.stop_confirmation'"
                    :clientname="selectedTraining.client.name"
                    v-if="dialog.confirmation"
                    @confirm="stopTraining"
                    @cancel="cancelStop"
                />

                <ConfirmationDialog
                    :name="selectedTraining.client ? selectedTraining.client.name : ''"
                    :text="'trainings.delete_confirmation'"
                    v-if="dialog.confirmationDelete"
                    @confirm="deleteTraining"
                    @cancel="cancelDelete"
                />

                <SnackMessage ref="SnackMessage" />
            </v-container>
        </section>
        <div class="text-center">
            <v-dialog v-model="resultDialog" max-width="700px">
                <v-tabs
                    fixed-tabs
                    background-color="indigo"
                    v-model="selectedStation"
                    color="white"
                >
                    <v-tab
                        v-for="station in selectedTraining.stations"
                        :key="station.id"
                        @click="seeResults(selectedTraining, station.id)"
                        dark
                    >
                        {{ station.name }}
                    </v-tab>
                </v-tabs>
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ $t("trainings.results") }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-container class="pt-4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left">
                                                            {{
                                                                $t(
                                                                    "trainings.error_name"
                                                                )
                                                            }}
                                                        </th>
                                                        <th class="text-left">
                                                            {{
                                                                $t(
                                                                    "trainings.number"
                                                                )
                                                            }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(
                                                            trainingError, index
                                                        ) in trainingErrors"
                                                        :key="index"
                                                    >
                                                        <td>
                                                            {{
                                                                trainingError.title
                                                            }}
                                                        </td>
                                                        <td>
                                                            {{
                                                                trainingError.count
                                                            }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn dark @click="closeResults" color="primary">
                            {{ $t("trainings.close") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <div>
            <v-dialog v-model="exportDialog" max-width="700px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ $t("trainings.export") }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-container class="pt-4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-select
                                            v-model="exportSelection"
                                            :items="clientsToExport"
                                            item-text="name"
                                            item-value="id"
                                        >
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <a
                            style="text-decoration: none"
                            :href="'/exportTrainings/' + exportSelection"
                            ><v-btn color="success" dark small>{{
                                $t("results.export")
                            }}</v-btn></a
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import TrainingForm from "../components/TrainingForm";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SnackMessage from "../components/SnackMessage";
import moment from "moment";

export default {
    data() {
        return {
            exportSelection: 0,
            clientsToExport: [],
            modulesToFilter: [],
            scenariosToFilter:[],
            companiesToFilter:[],
            active: null,
            statesToFilter: [],
            filtersHeader:{
                moduleValueFilter: null,
                scenarioValueFilter: null,
                clientValueFilter: null,
                companyValueFilter: null,
                stateValueFilter: null,
                createdValueFilter: null,
            },
            oculusDevicesAux: [],
            moduls: [],
            scenarios: [],
            stations: [],
            selectedStation: 0,
            resultDialog: false,
            exportDialog: false,
            trainingErrors: [],
            test: false,
            oculusDevices: [],
            clients: [],
            trainings: [],
            isTrainingsLoaded: false,
            dialog: {
                confirmation: false,
                trainingForm: false,
                confirmationDelete: false,
            },
            selectedTraining: {
                client_id: null,
                modul_id: null,
                scenario_id: null,
                oculus_id: null,
                state: 1,
            },
            defaultTraining: {
                client_id: null,
                modul_id: null,
                scenario_id: null,
                oculus_id: null,
                state: 1,
            },
            isTrainingEdited: false,
            menuFilter: false
        };
    },

    components: {
        TrainingForm,
        ConfirmationDialog,
        SnackMessage,
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t("general.actions"),
                    value: "action",
                    sortable: false,
                },
                {
                    text: this.$t("trainings.modul"),
                    align: "left",
                    sortable: false,
                    value: "modul",
                    filter: this.modulFilter,
                },
                {
                    text: this.$t("trainings.scenario"),
                    align: "left",
                    sortable: false,
                    value: "scenario",
                    filter: this.scenarioFilter,
                },
                {
                    text: this.$t("trainings.client"),
                    align: "left",
                    sortable: false,
                    value: "client",
                    filter: this.clientFilter,
                },
                {
                    text: this.$t("clients.company"),
                    align: "left",
                    sortable: false,
                    value: "client.company",
                    filter: this.companyFilter,
                },
                {
                    text: this.$t("trainings.state"),
                    align: "left",
                    sortable: false,
                    value: "state",
                    filter: this.stateFilter,
                },
                {
                    text: this.$t("trainings.finished_at"),
                    align: "left",
                    sortable: false,
                    value: "finished_at",
                },
                // {
                //     text: this.$t("trainings.number"),
                //     align: "left",
                //     sortable: true,
                //     value: "number",
                // },
                {
                    text: this.$t("general.created_at"),
                    align: "left",
                    sortable: false,
                    value: "created_at",
                    filter: this.createdFilter,

                },
            ];
        },
        formattedFilterDate: {
            get(){
                return (this.filtersHeader.createdValueFilter != null && this.filtersHeader.createdValueFilter != '')
                ? moment( this.filtersHeader.createdValueFilter).format("DD.MM.YYYY")
                :  this.filtersHeader.createdValueFilter;
            },
            set(value){
                this.filtersHeader.createdValueFilter = value
            } 
            
        },
    },

    created() {
        this.active = true
        this.getTrainings();
        this.getActiveOculus();
        this.getClients();
        this.getScenarios();
        this.getModuls();
        this.watchActiveTrainings();
    },

    destroyed() {
        this.active = false
        this.dialog.trainingForm = false
    },

    methods: {
        openForm(){
            this.selectedTraining = Object.assign({}, this.defaultTraining);
            this.dialog.trainingForm = true;
            this.isTrainingEdited = false;
        },
        snackMessage(msg, type) {
            this.$refs.SnackMessage.showMessage(msg, type);
        },

        async getTrainings() {
            await axios
                .get("/api/trainingList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.trainings = response.data;
                    this.isTrainingsLoaded = true;
                    this.getDataToFilter()
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        addTraining(training) {
            this.clientsToExport.push(training.client)
            this.modulesToFilter.push(training.modul)
            this.scenariosToFilter.push(training.scenario)
            this.companiesToFilter.push(training.client.company)
            this.trainings.unshift(training);
            this.hideOculus(training.oculus_id);
        },
        editTraining(item) {
            this.selectedTraining = Object.assign({}, item);
            this.dialog.trainingForm = true;
            this.isTrainingEdited = true;
        },
        updateTraining(training) {
            let index = this.trainings.findIndex(
                (item) => item.id == training.id
            );
            this.trainings.splice(index, 1, training);
        },
        confirmStop(item) {
            this.selectedTraining = item;
            this.dialog.confirmation = true;
        },
        confirmDelete(item) {
            this.selectedTraining = item;
            this.dialog.confirmationDelete = true;
        },
        cancelStop() {
            this.dialog.confirmation = false;
        },
        cancelDelete() {
            this.dialog.confirmationDelete = false;
        },
        async deleteTraining() {
            this.dialog.confirmationDelete = false;
            let index = this.trainings.indexOf(this.selectedTraining);

            await axios
                .delete(`/api/deleteTraining/${this.selectedTraining.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.selectedTraining = this.defaultTraining;
                    this.trainings.splice(index, 1);

                    this.$refs.SnackMessage.showMessage(
                        "trainings.deleted_successfully",
                        "success"
                    );
                })
                .catch((error) => {
                    console.log(error);
                    this.$refs.SnackMessage.showMessage(
                        "general.error",
                        "error"
                    );
                });
        },
        async getActiveOculus() {
            await axios
                .get("/api/oculusDeviceList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.oculusDevices = []
                    this.oculusDevicesAux = response.data;
                    response.data.forEach((element) => {
                        if (element.is_active && !this.isUsing(element)) {
                            this.oculusDevices.push(element);
                        }
                    });
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        async getClients() {
            await axios
                .get("/api/clientList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.clients = response.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        async getScenarios() {
            await axios
                .get("/api/scenarioList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.scenarios = response.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        async getModuls() {
            await axios
                .get("/api/modulList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.moduls = response.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        async seeResults(item, station_id) {
            this.resultDialog = true;
            await axios
                .get("/api/getTrainingErrors/" + item.id + "/" + station_id, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.trainingErrors = response.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        async stopTraining() {
            await axios
                .get(`/api/stopTraining/${this.selectedTraining.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    let index = this.trainings.findIndex(
                        (item) => item.id == response.data.id
                    );
                    this.trainings.splice(index, 1, response.data);
                    this.dialog.confirmation = false;
                    this.restoreOculus(response.data.oculus_id);
                    this.selectedTraining = this.defaultTraining;
                    this.$refs.SnackMessage.showMessage(
                        "trainings.stoped_successfully",
                        "success"
                    );
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },

        getDataToFilter () {
            let all = {
                id: 0,
                name: this.$t("general.all"),
            };
            this.modulesToFilter.push(all)
            this.scenariosToFilter.push(all)
            this.companiesToFilter.push(all)
            this.clientsToExport.push(all)
            for (let index = 0; index < this.trainings.length; index++) {
                const element = this.trainings[index];
                if(element.modul != null && !this.modulesToFilter.includes(element.modul)){
                    this.modulesToFilter.push(element.modul)
                }
                if(element.scenario != null && !this.scenariosToFilter.includes(element.scenario)){
                    this.scenariosToFilter.push(element.scenario)
                }
                if(element.client != null && !this.clientsToExport.includes(element.client)){
                    this.clientsToExport.push(element.client)
                }
                if(element.client != null && element.client.company != null && !this.companiesToFilter.includes(element.client.company)){
                    this.companiesToFilter.push(element.client.company)
                }
            }
            let state1 = {
                'name': this.$t('general.active'),
                'id': 2
            }
            let state2 = {
                'name': this.$t('trainings.done'),
                'id': 3
            }
            let state3 = {
                'name': this.$t('trainings.interrupted'),
                'id': 1
            }
            let state4 = {
                'name': this.$t('trainings.expired'),
                'id': 4
            }
            this.statesToFilter.push(all)
            this.statesToFilter.push(state1)
            this.statesToFilter.push(state2)
            this.statesToFilter.push(state3)
            this.statesToFilter.push(state4)
        },

        closeResults() {
            this.resultDialog = false;
            this.selectedStation = 1;
        },
        closeExport() {
            this.exportDialog = false;
        },
        openResults(item) {
            this.selectedTraining = item;
            this.selectedStation = 0;
            this.seeResults(item, item.stations[0].id);
        },
        isUsing(item) {
            let isUsing = false;
            for (
                let index = 0;
                index < this.trainings.length && !isUsing;
                index++
            ) {
                const element = this.trainings[index];
                if (element.state == 1 && element.oculus_id == item.oculus_id) {
                    isUsing = true;
                }
            }
            return isUsing;
        },
        hideOculus(oculus_id) {
            let index = this.oculusDevices.findIndex(
                (item) => item.oculus_id == oculus_id
            );
            this.oculusDevices.splice(index, 1);
        },
        restoreOculus(oculus_id) {
            let index = this.oculusDevicesAux.findIndex(
                (item) => item.oculus_id == oculus_id
            );
            this.oculusDevices.push(this.oculusDevicesAux[index]);
            this.oculusDevices.sort(function (a, b) {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });
        },
        clientFilter(value) {
            if (!this.filtersHeader.clientValueFilter) {
                return true;
            }
            return value != null && value.id == this.filtersHeader.clientValueFilter;
        },
        modulFilter(value) {
            if (!this.filtersHeader.modulValueFilter) {
                return true;
            }
            return value != null && value.id == this.filtersHeader.modulValueFilter;
        },
        scenarioFilter(value) {
            if (!this.filtersHeader.scenarioValueFilter) {
                return true;
            }
            return value != null && value.id == this.filtersHeader.scenarioValueFilter;
        },
        companyFilter(value) {
            if (!this.filtersHeader.companyValueFilter) {
                return true;
            }
            return value != null && value.id == this.filtersHeader.companyValueFilter;
        },
        stateFilter(value) {
            if (!this.filtersHeader.stateValueFilter) {
                return true;
            }
            return value != null && (value + 1) == this.filtersHeader.stateValueFilter;
        },
        createdFilter(value){
            if (!this.filtersHeader.createdValueFilter || this.formattedFilterDate == '') {
                return true;
            }
            if (value != null) {
                const date = String(value)
                const sp = date.split(' ')
                const format = sp[0]
                
                return format == String(this.formattedFilterDate)
            }else {
                return false
            }
            
        },
        watchActiveTrainings() {
            setInterval(() => {
                if (this.active){
                    this.getTrainings();
                }
                if(this.dialog.trainingForm){
                    this.getActiveOculus()
                }
            }, 3000);
        }
    },
};
</script>
