<template>
    <div>
        <header>
            <v-container>
                <v-row no-gutters>
                    <h1 class="font-weight-light headline">
                        {{ $t("title.oculus_devices") }}
                    </h1>
                    <v-spacer></v-spacer>
                    <v-lazy v-if="isOculusDevicesLoaded" transition="fade-transition">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="searchLabel"
                            single-line
                            dense
                            hide-details
                            style="max-width: 200px"
                            class="mr-5 pr-5"
                        ></v-text-field>
                    </v-lazy>
                    <!-- <v-lazy transition="fade-transition">
                        <v-btn
                            color="success"
                            dark
                            small
                            @click.stop="
                                dialog.oculusDeviceForm = true;
                                isOculusDeviceEdited = false;
                            "
                            >{{ $t("general.add") }}</v-btn
                        >
                    </v-lazy> -->
                </v-row>
            </v-container>
        </header>
        <section class="py-7">
            <v-container>
                <v-row v-if="isOculusDevicesLoaded && !oculusDevices.length">
                    <v-col cols="12" md="4" class="offset-md-4">
                        <v-alert
                            icon="mdi-information-outline"
                            type="info"
                            text
                            border="top"
                        >
                            {{ $t("general.we_dont_have_data_yet") }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-sheet class="px-3 pt-3 pb-3" v-if="!isOculusDevicesLoaded">
                    <v-skeleton-loader
                        class="mx-auto"
                        type="table"
                    ></v-skeleton-loader>
                </v-sheet>
                <v-card v-else>
                    <v-data-table
                    v-if="oculusDevices.length"
                        :headers="headers"
                        :items="oculusDevices"
                        :search="search"
                    >
                        <template v-slot:item.action="{ item }">
                            <div style="display: flex">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-tr-class-on-hover="'blue'"
                                            size="20px"
                                            color="blue"
                                            class="mr-2"
                                            @click="editOculusDevice(item)"
                                            v-on="on"
                                            >mdi-pencil-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.edit") }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            size="22px"
                                            color="red"
                                            v-tr-class-on-hover="'red'"
                                            class="mr-2"
                                            @click="confirmDelete(item)"
                                            v-on="on"
                                            >mdi-delete-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.delete") }}</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-slot:item.is_active="{ item }">
                            <v-switch
                                class="mt-0 pt-0 justify-content-center"
                                hide-details
                                readonly
                                v-model="item.is_active"
                                color="green"
                            ></v-switch>
                        </template>
                    </v-data-table>
                </v-card>
                <OculusDeviceForm
                    :oculusDevice="selectedOculusDevice"
                    :visible="dialog.oculusDeviceForm"
                    :isEdited="isOculusDeviceEdited"
                    :oculusDevices="oculusDevices"
                    @addOculusDevice="addOculusDevice"
                    @updateOculusDevice="updateOculusDevice"
                    @close="dialog.oculusDeviceForm = false"
                    @snackMessage="snackMessage"
                />

                <ConfirmationDialog
                    :name="selectedOculusDevice.name"
                    :text="'oculus_devices.delete_confirmation'"
                    v-if="dialog.confirmation"
                    @confirm="deleteOculusDevice"
                    @cancel="cancelDelete"
                />

                <SnackMessage ref="SnackMessage" />
            </v-container>
        </section>
    </div>
</template>

<script>
import OculusDeviceForm from "../components/OculusDeviceForm";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SnackMessage from "../components/SnackMessage";

export default {
    data() {
        return {
            active: null,
            oculusDevices: [],
            isOculusDevicesLoaded: false,
            search: "",
            dialog: {
                confirmation: false,
                oculusDeviceForm: false,
            },
            oculusDevice: {
                name: null,
                oculus_id: null,
                last_connection: null,
            },
            selectedOculusDevice: {
                name: null,
                oculus_id: null,
                last_connection: null,
            },
            defaultOculusDevice: {
                name: null,
                oculus_id: null,
                last_connection: null,
            },
            isOculusDeviceEdited: false,
        };
    },

    components: {
        OculusDeviceForm,
        ConfirmationDialog,
        SnackMessage,
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t("general.actions"),
                    value: "action",
                    sortable: false,
                },
                {
                    text: this.$t("oculus_devices.name"),
                    align: "left",
                    sortable: false,
                    value: "name",
                },
                {
                    text: this.$t("oculus_devices.oculus_id"),
                    align: "left",
                    sortable: false,
                    value: "oculus_id",
                },
                {
                    text: this.$t("oculus_devices.is_active"),
                    align: "left",
                    sortable: false,
                    value: "is_active",
                },
                {
                    text: this.$t("oculus_devices.last_connection"),
                    align: "left",
                    sortable: false,
                    value: "last_connection",
                },
                {
                    text: this.$t("general.created_at"),
                    align: "left",
                    sortable: false,
                    value: "created_at",
                }
            ];
        },
        searchLabel() {
            return this.$t("general.search");
        },
    },

    created() {
        this.active = true
        this.getOculusDevices();
        this.watchActiveOculus();
    },

    methods: {
        snackMessage(msg, type) {
            this.$refs.SnackMessage.showMessage(msg, type);
        },

        async getOculusDevices() {
            await axios
                .get("/api/oculusDeviceList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.oculusDevices = response.data;
                    this.isOculusDevicesLoaded = true;
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        addOculusDevice(oculusDevice) {
            this.oculusDevices.push(oculusDevice);
        },
        editOculusDevice(item) {
            this.selectedOculusDevice = Object.assign({}, item);
            this.dialog.oculusDeviceForm = true;
            this.isOculusDeviceEdited = true;
        },
        updateOculusDevice(oculusDevice) {
            let index = this.oculusDevices.findIndex((item) => item.id == oculusDevice.id);
            this.oculusDevices.splice(index, 1, oculusDevice);
        },
        confirmDelete(item) {
            this.selectedOculusDevice = item;
            this.dialog.confirmation = true;
        },
        cancelDelete() {
            this.dialog.confirmation = false;
        },
        async deleteOculusDevice() {
            this.dialog.confirmation = false;
            let index = this.oculusDevices.indexOf(this.selectedOculusDevice);

            await axios
                .delete(`/api/deleteOculusDevice/${this.selectedOculusDevice.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.selectedOculusDevice = this.defaultOculusDevice;
                    this.oculusDevices.splice(index, 1);

                    this.$refs.SnackMessage.showMessage(
                        "oculus_devices.deleted_successfully",
                        "success"
                    );
                })
                .catch((error) => {
                    console.log(error);
                    this.$refs.SnackMessage.showMessage(
                        "general.error",
                        "error"
                    );
                });
        },
        watchActiveOculus() {
            setInterval(() => {
                if (this.active){
                    this.getOculusDevices();
                }
            }, 3000);
        }
    },
    destroyed(){
        this.active = false        
    }
};
</script>
