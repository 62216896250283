<template>
    <v-dialog v-model="show" max-width="400px">
        <v-card>
            <v-card-title>
                <span class="headline" v-if="isEdited">{{
                    $t("companies.edit_company")
                }}</span>
                <span class="headline" v-else>{{
                    $t("companies.add_company")
                }}</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="company.name"
                                    :label="$t('companies.name')"
                                    autofocus
                                    :rules="rules"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark @click="submit">{{
                    $t("general.save")
                }}</v-btn>
                <v-btn @click.stop="show = false">{{
                    $t("general.cancel")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "CompanyForm",
    props: {
        form: {
            type: Object,
        },
        company: Object,
        visible: Boolean,
        isEdited: Boolean,
    },

    data() {
        return {
            checkedDefault: true,
            valid: true,
            showEye: false,
            rules: [(value) => !!value || this.$t("general.required")],
            emailRules: [
                (value) => !!value || this.$t("general.required"),
                (value) =>
                    /.+@.+/.test(value) || this.$t("general.email_not_valid"),
            ],
        };
    },

    watch: {
        show(val) {
            !val && this.$refs.form.reset();
        },
    },
    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) {
                    this.$emit("close");
                }
            },
        },
    },

    methods: {
        async submit() {
            if (this.$refs.form.validate()) {
                if (this.isEdited) {
                    await axios
                        .put(
                            `/api/updateCompany/${this.company.id}`,
                            this.company,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " +
                                        this.$store.state.tokenData.user
                                            .access_token,
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.isError) {
                                this.$emit(
                                    "snackMessage",
                                    response.data.message,
                                    "warning"
                                );
                                this.$refs.email.focus();
                            } else {
                                // response.data.roleName = response.data.role == "admin" ? "Admin": "Uživatel";
                                this.$emit("updateCompany", response.data);
                                this.$emit(
                                    "snackMessage",
                                    "companies.company_saved",
                                    "success"
                                );
                                this.$emit("close");
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                } else {
                    await axios
                        .post(`/api/storeCompany`, this.company, {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    this.$store.state.tokenData.user
                                        .access_token,
                            },
                        })
                        .then((response) => {
                            if (response.data.isError) {
                                this.$refs.email.focus();
                                this.$emit(
                                    "snackMessage",
                                    response.data.message,
                                    "warning"
                                );
                            } else {
                                this.$emit("addCompany", response.data);
                                this.$emit(
                                    "snackMessage",
                                    "companies.company_added",
                                    "success"
                                );
                                this.$emit("close");
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                }
            }
        },
    },
};
</script>
