<template>
    <v-dialog v-model="show" max-width="700px">
        <v-card>
            <v-card-title>
                <span class="headline" v-if="isEdited">{{
                    $t("oculus_devices.edit_oculus_device")
                }}</span>
                <span class="headline" v-else>{{
                    $t("oculus_devices.add_oculus_device")
                }}</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="5">
                                <v-text-field
                                    v-model="oculusDevice.oculus_id"
                                    :label="$t('oculus_devices.oculus_id')"
                                    autofocus
                                    :rules="rulesId"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-text-field
                                    v-model="oculusDevice.name"
                                    :rules="rules"
                                    :label="$t('oculus_devices.name')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark @click="submit">{{
                    $t("general.save")
                }}</v-btn>
                <v-btn @click.stop="show = false">{{
                    $t("general.cancel")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "OculusDeviceForm",
    props: {
        form: {
            type: Object
        },
        oculusDevice: Object,
        visible: Boolean,
        isEdited: Boolean,
        oculusDevices: Array
    },

    data() {
        return {
            checkedDefault: true,
            valid: true,
            showEye: false,
            rules: [value => !!value || this.$t("general.required")],
            rulesId: [
                value =>
                    !!value || this.$t("general.required"),
                value =>
                    !this.exists(value) ||
                    this.$t("oculus_devices.oculus_id_exists")
                ]
        };
    },

    watch: {
        show(val) {
            !val && this.$refs.form.reset();
        }
    },

    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) {
                    this.$emit("close");
                }
            }
        }
    },

    methods: {
        async submit() {
            if (this.$refs.form.validate()) {
                if (this.isEdited) {
                    await axios
                        .put(
                            `/api/updateOculusDevice/${this.oculusDevice.id}`,
                            this.oculusDevice,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " +
                                        this.$store.state.tokenData.user
                                            .access_token
                                }
                            }
                        )
                        .then(response => {
                            if (response.data.isError) {
                                this.$emit(
                                    "snackMessage",
                                    response.data.message,
                                    "warning"
                                );
                                this.$refs.email.focus();
                            } else {
                                // response.data.roleName = response.data.role == "admin" ? "Admin": "Uživatel";
                                this.$emit("updateOculusDevice", response.data);
                                this.$emit(
                                    "snackMessage",
                                    "oculus_devices.oculus_device_saved",
                                    "success"
                                );
                                this.$emit("close");
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                } else {
                    await axios
                        .post(`/api/storeOculusDevice`, this.oculusDevice, {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    this.$store.state.tokenData.user
                                        .access_token
                            }
                        })
                        .then(response => {
                            if (response.data.isError) {
                                this.$refs.email.focus();
                                this.$emit(
                                    "snackMessage",
                                    response.data.message,
                                    "warning"
                                );
                            } else {
                                this.$emit("addOculusDevice", response.data);
                                this.$emit(
                                    "snackMessage",
                                    "oculus_devices.oculus_device_added",
                                    "success"
                                );
                                this.$emit("close");
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                }
            }
        },
        exists(value) {
            let idExists = false
            for (let index = 0; index < this.oculusDevices.length; index++) {
                const element = this.oculusDevices[index];
                if(element.oculus_id === value && this.oculusDevice.id != element.id){
                    idExists = true
                }
            }
            return idExists
        }
    }
};
</script>
