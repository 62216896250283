<template>
    <div>
        <header>
            <v-container>
                <v-row no-gutters>
                    <h1 class="font-weight-light headline">
                        {{ $t("title.companies") }}
                    </h1>
                    <v-spacer></v-spacer>
                    <v-lazy v-if="isCompaniesLoaded" transition="fade-transition">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="searchLabel"
                            single-line
                            dense
                            hide-details
                            style="max-width: 200px"
                            class="mr-5 pr-5"
                        ></v-text-field>
                    </v-lazy>
                    <v-lazy transition="fade-transition">
                        <v-btn
                            color="success"
                            dark
                            small
                            @click.stop="
                                dialog.companyForm = true;
                                isCompanyEdited = false;
                            "
                            >{{ $t("general.add") }}</v-btn
                        >
                    </v-lazy>
                </v-row>
            </v-container>
        </header>
        <section class="py-7">
            <v-container>
                <v-sheet class="px-3 pt-3 pb-3" v-if="!isCompaniesLoaded">
                    <v-skeleton-loader
                        class="mx-auto"
                        type="table"
                    ></v-skeleton-loader>
                </v-sheet>
                <v-card v-else>
                    <v-data-table
                        :headers="headers"
                        :items="companies"
                        :search="search"
                    >
                        <template v-slot:item.action="{ item }">
                            <div style="display: flex">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-tr-class-on-hover="'blue'"
                                            size="20px"
                                            color="blue"
                                            class="mr-2"
                                            @click="editUser(item)"
                                            v-on="on"
                                            >mdi-pencil-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.edit") }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            size="22px"
                                            color="red"
                                            v-tr-class-on-hover="'red'"
                                            class="mr-2"
                                            @click="confirmDelete(item)"
                                            v-on="on"
                                            >mdi-delete-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.delete") }}</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <!-- <template v-slot:item.is_active="{ item }">
                            <v-switch
                                class="mt-0 pt-0 justify-content-center"
                                hide-details
                                color="green"
                                v-model="item.is_active"
                                @change="changeStatus(item)"
                            ></v-switch>
                        </template> -->
                    </v-data-table>
                </v-card>
                <CompanyForm
                    :company="selectedCompany"
                    :visible="dialog.companyForm"
                    :isEdited="isCompanyEdited"
                    @addCompany="addCompany"
                    @updateCompany="updateCompany"
                    @close="dialog.companyForm = false"
                    @snackMessage="snackMessage"
                />

                <ConfirmationDialog
                    :name="selectedCompany.name"
                    :text="'companies.delete_confirmation'"
                    v-if="dialog.confirmation"
                    @confirm="deleteCompany"
                    @cancel="cancelDelete"
                />

                <SnackMessage ref="SnackMessage" />
            </v-container>
        </section>
    </div>
</template>

<script>
import CompanyForm from "../components/CompanyForm";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SnackMessage from "../components/SnackMessage";

export default {
    data() {
        return {
            companies: [],
            isCompaniesLoaded: false,
            search: "",
            dialog: {
                confirmation: false,
                companyForm: false,
            },
            company: {
                name: null,
                is_active: null
            },
            selectedCompany: {
                name: null,
                is_active: null
            },
            defaultCompany: {
                name: null,
                is_active: null
            },
            isCompanyEdited: false,
        };
    },

    components: {
        CompanyForm,
        ConfirmationDialog,
        SnackMessage,
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t("general.actions"),
                    value: "action",
                    sortable: false,
                },
                {
                    text: this.$t("companies.name"),
                    align: "left",
                    sortable: false,
                    value: "name",
                },
                // {
                //     text: this.$t("companies.is_active"),
                //     align: "left",
                //     sortable: false,
                //     value: "is_active",
                // },
                {
                    text: this.$t("general.created_at"),
                    align: "left",
                    sortable: false,
                    value: "created_at",
                },
            ];
        },
        searchLabel() {
            return this.$t("general.search");
        },
    },

    created() {
        this.getCompanies();
    },

    methods: {
        snackMessage(msg, type) {
            this.$refs.SnackMessage.showMessage(msg, type);
        },

        async getCompanies() {
            await axios
                .get("/api/companyList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.companies = response.data;
                    this.isCompaniesLoaded = true;
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        addCompany(company) {
            this.companies.push(company);
            this.getCompanies()
        },
        editUser(item) {
            this.selectedCompany = Object.assign({}, item);
            this.dialog.companyForm = true;
            this.isCompanyEdited = true;
        },
        updateCompany(company) {
            let index = this.companies.findIndex((item) => item.id == company.id);
            this.companies.splice(index, 1, company);
            this.getCompanies()
        },
        confirmDelete(item) {
            this.selectedCompany = item;
            this.dialog.confirmation = true;
        },
        cancelDelete() {
            this.dialog.confirmation = false;
        },
        async deleteCompany() {
            this.dialog.confirmation = false;
            let index = this.companies.indexOf(this.selectedCompany);

            await axios
                .delete(`/api/deleteCompany/${this.selectedCompany.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.selectedCompany = this.defaultCompany;
                    this.companies.splice(index, 1);

                    this.$refs.SnackMessage.showMessage(
                        "companies.deleted_successfully",
                        "success"
                    );
                })
                .catch((error) => {
                    console.log(error);
                    this.$refs.SnackMessage.showMessage(
                        "general.error",
                        "error"
                    );
                });
        },
        async changeStatus(item) {
            try {
                const request = await axios.put(
                    "/api/updateStatusCompany/" + item.id,
                    item,
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                this.$store.state.tokenData.user.access_token,
                        },
                    }
                );
                this.getCompanies()
                this.snackMessage("general.item_saved", "success");
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>
